@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;500;700;900&display=swap');
body, html {height:100%;}
body{
    background-color: #212529;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #f5f5f5;
    margin: 0;
    overflow: hidden; 
    background-image: url(../public/images/bg.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home {
    color: white;
    font-family: 'Noto Sans', sans-serif;

    .main-text{
        z-index: 1;
        font-family: 'Courier New', Courier, monospace;
        position: absolute;
        left: 50%;
        bottom: 20vh;
        font-size: 3rem;
        text-align: center;
        pointer-events: none;
    }
    .main-text.active-slide, .main-text.active-slide-fast{
        transform: translateX(-50%);
        opacity: 1;
        transition: all 0.5s ease-in-out;
        user-select: text;
        pointer-events: visible;
    }
    .main-text{
        opacity: 0;
        transform: translateX(-50%) translateY(10vh);
        transition: all 0.5s ease-in;
    }

    .main-text .blinking{
        animation: blink 1s infinite step-end
    }

    @keyframes blink {
        0% {
            background-color: transparent;
        }
        49% {
            background-color: transparent;
        }
        50% {
            background-color: white;
        }
        99% {
            background-color: white;
        }
        100% {
            background-color: transparent;
        }
        
    }

    .scroll-icon{
        padding: 10px 20px;
        background-color: white;
        /* stylelint-disable */
        // -webkit-mask: url(/images/icons/mouse_scroll.svg) no-repeat center !important;
        mask: url(../public/images/icons/mouse_scroll.svg) no-repeat center !important;
    }
    .scroll-advice{
        font-size: 1rem;
        margin-top: 10vh;
    }

    .slide-container{
        position: absolute;
        top: 50%;
        left: 0;
        right: unset;
        width: 75%;
        margin: 0 10vw;
        max-width: 600px;
        opacity: 0;
        transform: translateY(-50%) translateX(-10%);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in;
        pointer-events: none;
    }
    .slide-container.active-slide{
        opacity: 1;
        transform: translateY(-50%);
        transition: all 1s 1.5s ease-in-out;
        user-select: text;
        pointer-events: visible;
    }
    .slide-container.active-slide-fast{
        opacity: 1;
        transform: translateY(-50%);
        transition: all 1s ease-in-out;
        user-select: text;
        pointer-events: visible;
    }

    .slide-container:nth-child(odd){
        text-align: right;
        left: unset;
        right: 0;
    }

    .slide-title{
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    .slide-content{
        font-weight: 200;
    }

    //on mobile
    @media (max-width: 700px) {
        .slide-container{
            top: 75%;
        }
    }
}