@import "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;500;700;900&display=swap";
body, html {
  height: 100%;
}

body {
  color: #f5f5f5;
  background-color: #212529;
  background-image: url("bg.dda549dd.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
}

.home {
  color: #fff;
  font-family: Noto Sans, sans-serif;
}

.home .main-text {
  z-index: 1;
  text-align: center;
  pointer-events: none;
  font-family: Courier New, Courier, monospace;
  font-size: 3rem;
  position: absolute;
  bottom: 20vh;
  left: 50%;
}

.home .main-text.active-slide, .home .main-text.active-slide-fast {
  opacity: 1;
  -webkit-user-select: text;
  user-select: text;
  pointer-events: visible;
  transition: all .5s ease-in-out;
  transform: translateX(-50%);
}

.home .main-text {
  opacity: 0;
  transition: all .5s ease-in;
  transform: translateX(-50%)translateY(10vh);
}

.home .main-text .blinking {
  animation: 1s step-end infinite blink;
}

@keyframes blink {
  0% {
    background-color: #0000;
  }

  49% {
    background-color: #0000;
  }

  50% {
    background-color: #fff;
  }

  99% {
    background-color: #fff;
  }

  100% {
    background-color: #0000;
  }
}

.home .scroll-icon {
  background-color: #fff;
  padding: 10px 20px;
  -webkit-mask: url("mouse_scroll.37affbdd.svg") center no-repeat !important;
  mask: url("mouse_scroll.37affbdd.svg") center no-repeat !important;
}

.home .scroll-advice {
  margin-top: 10vh;
  font-size: 1rem;
}

.home .slide-container {
  top: 50%;
  left: 0;
  right: unset;
  opacity: 0;
  pointer-events: none;
  width: 75%;
  max-width: 600px;
  margin: 0 10vw;
  transition: opacity .5s ease-in-out, transform .5s ease-in;
  position: absolute;
  transform: translateY(-50%)translateX(-10%);
}

.home .slide-container.active-slide {
  opacity: 1;
  -webkit-user-select: text;
  user-select: text;
  pointer-events: visible;
  transition: all 1s ease-in-out 1.5s;
  transform: translateY(-50%);
}

.home .slide-container.active-slide-fast {
  opacity: 1;
  -webkit-user-select: text;
  user-select: text;
  pointer-events: visible;
  transition: all 1s ease-in-out;
  transform: translateY(-50%);
}

.home .slide-container:nth-child(odd) {
  text-align: right;
  left: unset;
  right: 0;
}

.home .slide-title {
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 600;
}

.home .slide-content {
  font-weight: 200;
}

@media (width <= 700px) {
  .home .slide-container {
    top: 75%;
  }
}

/*# sourceMappingURL=index.c1c8a478.css.map */
